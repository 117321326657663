import "./index.scss";
import React, { useEffect, useState } from "react";
import { Drawer, Button, Table, message, Modal, Image } from "antd";
import { CloseOutlined, LinkOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import projectApi from "../../apis/project";
import bondApi from "../../apis/bond";

import HeaderTitle from "../HeaderTitle";
import MySpin from "../MySpin";
import moment from "moment";
import { showInfoFunc } from "../MyTable";
import { addMillimeter, guid, isImage } from "../../unit/unit.js";
import InfoIcon from "../Icons/InfoIcon/index.jsx";
import MyEmpty from "../Empty/index.jsx";
const DetailsDrawer = (props) => {
  const { id } = useParams();

  const {
    /* 标题 */
    title,
    /* 关闭触发的回调 */
    onCancel,
    dataTable,
    item,
    visible,
    typeName,
    isBid,
    projectId,
    is_suspect_record,
    isBond = false,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [showList, setShowList] = useState([]);
  const [isDelete, serIsDelete] = useState(false);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const initList = (rows, titles, info) => {
    rows = Object.keys(rows).map((key) => {
      return {
        key: key,
        name: key,
        ...rows[key],
      };
    });
    titles = titles.map((item, index) => {
      if (index === 0 && info?.comments?.length > 0 && !isBid) {
        item.comments = info?.comments;
      }
      item.list = rows
        .filter((_) => _.title === item.title)
        .sort((a, b) => a.index - b.index);
      return item;
    });
    setShowList(titles);
  };

  const getData = () => {
    let api = projectApi;
    if (isBond) {
      api = bondApi;
    }
    api
      .getTableDetail(
        isBond ? item.id : item.id?.val,
        isBond ? item.event_code : dataTable,
        id ? id : projectId,
        isBid ? 1 : undefined,
        is_suspect_record ? 1 : undefined,
        item?.company_id,
        item?.composite_id
      )
      .then((res) => {
        console.log("详情信息", res);
        if (res.code === 200) {
          if (res.data?.data?.length === 0) {
            // message.warning(res?.message)
            // handleCancel()
            serIsDelete(true);
            setLoading(false);
            return;
          }
          setData(res.data?.data?.at(0));
          initList(res.data.rows, res.data.titles, res.data.data?.at(0));
          setLoading(false);
        } else {
          message.error(res.message);
          handleCancel();
        }
      });
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      getData();
    } else {
      setData({});
      setShowList([]);
      serIsDelete(false);
    }
  }, [visible]);

  //根据链接获取文件名称
  const getUrlTitle = (url) => {
    const arr = url?.split("/");
    return arr?.at(arr.length - 1);
  };
  //下载
  const download = (url) => {
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.target = "_self";
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const initDom = (item) => {
    //对象 需要遍历原数组 循环 key-value 展示
    if (item.is_object) {
      return data[item.key]?.val?.map((_item) => (
        <div
          className="detailItem"
          key={_item.title}
          style={{
            width: "100%",
          }}
        >
          <div className="detailKey">{_item.title}</div>
          <div className="detailValue">{_item.content || "-"}</div>
        </div>
      ));
    }
    //表格
    if (item.is_table) {
      const columns = Object.keys(item.rows)
        .map((key) => {
          return {
            key: key,
            title: item.rows[key]?.name,
            dataIndex: key,
            align: "center",
            index: item.rows[key]?.index,
            render: (text, record, index) => {
              if (text === null || text === undefined) {
                return "-";
              }
              if (item.rows[key]?.need_millimeter) {
                return addMillimeter(text, 2);
              }
              if (item.rows[key]?.is_time_stamp) {
                return moment(new Date(text)).format("YYYY-MM-DD");
              }
              return text;
            },
          };
        })
        .sort((a, b) => a.index - b.index);
      const info = data[item.key]?.val;
      return (
        <div style={{ width: "100%" }} key={Math.random()}>
          <Table
            style={{ marginTop: "10px" }}
            rowKey={(record) => Math.random()}
            className="myFormTable detailTable"
            bordered={true}
            pagination={false}
            columns={columns}
            dataSource={info}
          />
        </div>
      );
    }
    //对象数组
    if (item.is_object_array) {
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: "100%",
          }}
        >
          <div className="detailKey">{item.name}</div>
          <div className="detailValue">
            {data[item.key]?.val
              ? data[item.key]?.val
                  ?.map((_) => _[item?.get_field])
                  ?.join(item?.is_join)
              : "-"}
          </div>
        </div>
      );
    }
    //司法拍卖 标的物介绍 竞选公告 竞买须知 合并
    if (item.key === "itemFormUrl") {
      return (
        <div key={Math.random()} className="itemFormUrlBox">
          {[
            { key: "itemFormUrl", name: "标的物介绍" },
            { key: "content", name: "竞买公告" },
            { key: "biddingInstructionsUrl", name: "竞买须知" },
          ].map((_item) => (
            <div className="detailFileBox" key={Math.random()}>
              <div
                className={
                  data[_item.key]?.val && !data[_item.key]?.not_parse_flag
                    ? "detailUrl"
                    : "detailUrl disUrl"
                }
                onClick={() => {
                  if (data[_item.key]?.not_parse_flag) {
                    return;
                  }
                  data[_item.key]?.val && showDetailHtml(data[_item.key]?.val);
                }}
              >
                {_item.name}
              </div>
              {data[_item.key]?.not_parse_flag && (
                <div
                  className="fileUrl"
                  onClick={() => {
                    download(data[_item.key]?.val);
                  }}
                >
                  <LinkOutlined />
                  {getUrlTitle(data[_item.key]?.val)}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
    if (
      (item.key === "content" || item.key === "biddingInstructionsUrl") &&
      (typeName === "司法拍卖" || typeName === "司法案件")
    ) {
      return <div key={Math.random()}></div>;
    }
    //附件链接
    if (item.is_file) {
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: "100%",
          }}
        >
          <div className="detailKey">{item.name}</div>
          <div className="detailValue">
            {data[item.key]?.val?.map((_item) => (
              <div
                className="fileUrl"
                key={Math.random()}
                onClick={() => {
                  download(_item);
                }}
              >
                <LinkOutlined /> {getUrlTitle(_item)}
              </div>
            ))}
          </div>
        </div>
      );
    }
    //图片数组
    if (item.is_image) {
      return (
        <div className="detailImgBox">
          {data[item.key]?.val?.map((_item) => (
            <Image width={110} src={_item} key={_item} />
          ))}
        </div>
      );
    }
    if (item.name === "商标") {
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: item.width === 100 ? "100%" : `calc(${item.width}% - 10px)`,
          }}
        >
          {item.name && <div className="detailKey">{item.name}</div>}
          <div className="detailValue">
            {data[item.key]?.val ? (
              <Image
                width={80}
                height={"auto"}
                src={data[item.key]?.val}
                key={data[item.key]?.val}
              />
            ) : (
              "-"
            )}
          </div>
        </div>
      );
    }
    //图片或者pdf
    if (item.is_image_or_file) {
      if (!data[item.key]?.val) {
        return null;
      }
      // const isPdf = data[item.key]?.val?.slice(-3) === "pdf";
      const isImg = isImage(data[item.key]?.val || "");
      if (isImg) {
        return (
          <div className="detailImgBox">
            <Image
              width={"100%"}
              height={"auto"}
              src={data[item.key]?.val}
              key={data[item.key]?.val}
            />
          </div>
        );
      }
      const fileName = data[item.key]?.val?.slice(
        data[item.key]?.val?.lastIndexOf("/") + 1
      );
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: "100%",
          }}
        >
          <a href={data[item.key]?.val} target="_blank" rel="noreferrer">
            {fileName}
          </a>
        </div>
      );
    }
    //可点击查看详情
    if (
      item.click_natural_request ||
      item.click_legal_request ||
      item.click_all
    ) {
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: item.width === 100 ? "100%" : `calc(${item.width}% - 10px)`,
          }}
        >
          {item.name && <div className="detailKey">{item.name}</div>}
          <div className="detailValue">
            <a
              onClick={(e) => {
                e.stopPropagation();
                showInfoFunc(item, data, typeName, id ? id : projectId, item.key);
              }}
            >
              {data[item.key]?.val}
            </a>
          </div>
        </div>
      );
    }
    //可点击查看详情
    if (item.is_herf) {
      return (
        <div
          className="detailItem"
          key={item.key}
          style={{
            width: item.width === 100 ? "100%" : `calc(${item.width}% - 10px)`,
          }}
        >
          {item.name && <div className="detailKey">{item.name}</div>}
          <div className="detailValue">
            <a href={data[item.key]?.val} target="_black">
              {data[item.key]?.val}
            </a>
          </div>
        </div>
      );
    }
    //key-value 展示
    return (
      <div
        className="detailItem"
        key={item.key}
        style={{
          width: item.width === 100 ? "100%" : `calc(${item.width}% - 10px)`,
        }}
      >
        {item.name && <div className="detailKey">{item.name}</div>}
        <div className="detailValue">
          {data[item.key]?.val
            ? item.need_millimeter
              ? addMillimeter(data[item.key]?.val)
              : data[item.key]?.val === "1970-01-01"
              ? "-"
              : data[item.key]?.val //排除1970-01-01 日期显示
            : "-"}
        </div>
      </div>
    );
  };
  const showDetailHtml = (html) => {
    setModalVisible(true);
    setTimeout(() => {
      const dom = document.documentElement.querySelector("#detailHtmlBox");
      html = html?.replace(/<a\b[^>]*>(.*?)<\/a>/gi,'$1')
      // html = html?.replace(/<a/gi,'<span')
      // html = html?.replace(/<\/a>/gi,'</span>')
      dom.innerHTML = html;
    }, 100);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width="600px"
      className="detailsDrawer"
      onClose={handleCancel}
      visible={visible}
      push={false}
      closable={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            handleCancel();
          }}
        />
      }
      footer={
        <div className="drawerFooterBtnBox">
          <Button
            onClick={() => {
              handleCancel();
            }}
          >
            关闭
          </Button>
        </div>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        {loading ? (
          <MySpin></MySpin>
        ) : isDelete ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MyEmpty description='该数据已被删除，无法查看详情信息'></MyEmpty>
          </div>
        ) : (
          <div className="detailsContent">
            {showList.map((item) => (
              <div
                key={item.title}
                style={{ width: "100%", marginBottom: "15px" }}
              >
                <HeaderTitle
                  title={
                    item?.comments ? (
                      <>
                        <span style={{ marginRight: "5px" }}>{item.title}</span>
                        <InfoIcon
                          info={{
                            comments: item?.comments,
                            id: guid(),
                          }}
                          // trigger="hover"
                          dataTable={dataTable}
                          typeName={typeName}
                          projectId={projectId}
                          mode={30}
                          tipsText="修改历史"
                          forceRender={true}
                        ></InfoIcon>
                      </>
                    ) : (
                      item.title
                    )
                  }
                ></HeaderTitle>

                <div className="detailsBox">
                  {item.list.map((_item) => initDom(_item))}
                </div>
              </div>
            ))}
          </div>
        )}
      </Scrollbars>
      <Modal
        centered
        visible={modalVisible}
        footer={null}
        wrapClassName="detailModal"
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        width={1000}
      >
        <div id="detailHtmlBox"></div>
      </Modal>
    </Drawer>
  );
};
export default DetailsDrawer;
